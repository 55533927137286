import { create } from 'zustand';
import { FeatureFlags } from './adapters';

interface FeatureFlagStoreState {
  flags: FeatureFlags;
  hasFetched: boolean;
  setFlags: (value: FeatureFlags) => void;
  setHasFetched: (value: boolean) => void;
}

/*
 * Zustand store is a hook. You can put anything in it: primitives, objects,
 * and functions. State has to be updated immutably and the set function merges
 * state to help it.
 */
export const useFeatureFlagStore = create<FeatureFlagStoreState>()((set) => ({
  flags: {},
  hasFetched: false,
  setFlags: (flags) => set((state) => ({ ...state, flags })),
  setHasFetched: (hasFetched) => set((state) => ({ ...state, hasFetched })),
}));

/*
 * Hooks are the primary means of consuming state. Flag is exposed here with a
 * helper hook function.
 */
export const useIsFeatureFlagEnabled = (flag: string, defaultValue = false) => {
  const flags = useFeatureFlagStore((state) => state.flags);
  const featureFlag = flags[flag];
  return featureFlag?.isEnabled ?? defaultValue;
};

/**
 * Get the variant attachment for a feature flag.
 * For feature flags with variants, this will return the attachment data.
 */
export const useFeatureFlagVariant = (flag: string) => {
  const flags = useFeatureFlagStore((state) => state.flags);
  const featureFlag = flags[flag];
  return featureFlag?.value as Record<string, unknown> | undefined;
};
