import { ComponentPropsWithoutRef } from 'react';

export default function CheckedIcon(props: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg width="25px" height="26px" viewBox="0 0 24 24" {...props}>
      <g transform="matrix(1 0 0 1 -.497 0)" fill="none" fillRule="evenodd">
        <circle cx="12.5" cy="12" r="11.5" stroke="currentColor" />
        <circle
          cx="12.5"
          cy="12"
          r="9.2"
          fill={props.color}
          stroke="currentColor"
        />
        <polyline
          points="17.2 6.9 9.92 15 6.9 11.6"
          stroke="#fff"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}
