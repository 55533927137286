import { useSelector } from 'react-redux';
import { StoredEntry } from '@paradigm/desktop/src/utils/stored-entry';
import {
  FEATURE_UM_UI_OB_V1_API,
  FEATURE_UM_RFQ_HIDDEN_LIMIT_ORDER,
} from '@paradigm/utils/src/constants';
import { getUserTicker } from '@paradigm/store/auth';
import { FeatureFlags } from './adapters';
import { useIsFeatureFlagEnabled, useFeatureFlagVariant } from './store';

// Helper hook functions that are used to check if a feature flag is enabled.
export const useMarketIntegrityFeatureFlag = () => {
  return useIsFeatureFlagEnabled('feature_market_integrity');
};

export const useMarketIntegrityMarginFeatureFlag = () => {
  return useIsFeatureFlagEnabled('feature_market_integrity_margin');
};

export const useGrfqRfqAbsence = () => {
  return useIsFeatureFlagEnabled('logs_grfq_message_absence_period');
};

export const useGrfqAdditionalLogs = () => {
  return useIsFeatureFlagEnabled('logs_grfq_message_recieved');
};

export const useUserPreferencesFeatureFlag = () => {
  return useIsFeatureFlagEnabled('feature_um_user_preferences_json');
};

export const useDefaultAccountsFeatureFlag = () => {
  return useIsFeatureFlagEnabled('feature_um_ui_default_accounts');
};

export const useSpreadFromMidFeatureFlag = () => {
  return useIsFeatureFlagEnabled('feature_um_ui_spread_from_mid');
};

export const usePremiumHedgeQuantityCalculation = () => {
  return useIsFeatureFlagEnabled('feature_um_ui_premium_hedge_calculation');
};

export const useObMatchingQuoteFeatureFlag = () => {
  return useIsFeatureFlagEnabled('feature_um_ui_ob_matching_quote');
};

/**
 * Feature flag for the streaming greeks feature
 * Remove this interface once the feature flag is removed
 */

export const useUnifiedMarketsV2PricingAlgorithmFeatureFlag = () => {
  return useIsFeatureFlagEnabled('feature_um_v2_pricing_algorithm');
};

export const useUnifiedMarketsPlatformStateFeatureFlag = () => {
  return useIsFeatureFlagEnabled('feature_flag_um_ui_platform_state');
};

export const useUmTakerProfileFeatureFlag = () => {
  return useIsFeatureFlagEnabled('feature_um_taker_profile');
};

export const useDbtAltCoinsRfqFeatureFlag = () => {
  return useIsFeatureFlagEnabled('feature_dbt_alt_coins');
};

export const useDbtAltCoinsObFeatureFlag = () => {
  return useIsFeatureFlagEnabled('feature_dbt_alt_coins_ob_auctions');
};

export const useOBV1APIFeatureFlag = () => {
  return useIsFeatureFlagEnabled(FEATURE_UM_UI_OB_V1_API);
};

export const useHiddenLimitOrderFeatureFlag = () => {
  const isBaseFeatureEnabled = useIsFeatureFlagEnabled(
    FEATURE_UM_RFQ_HIDDEN_LIMIT_ORDER,
  );
  const variant = useFeatureFlagVariant(FEATURE_UM_RFQ_HIDDEN_LIMIT_ORDER);
  const userTicker = useSelector(getUserTicker);

  if (!isBaseFeatureEnabled) {
    return false;
  }

  // If there's no variant or user ticker, use the base feature flag
  if (!variant || !userTicker) {
    return isBaseFeatureEnabled;
  }

  // If the tickers array is empty, enable for all
  const allowedTickers = (variant.tickers as string[]) || [];
  if (allowedTickers.length === 0) {
    return true;
  }

  // Check if the user's ticker is in the allowed list
  return allowedTickers.includes(userTicker);
};

export const featureFlagsMigrator = (state: string): FeatureFlags => {
  try {
    return JSON.parse(state) as FeatureFlags;
  } catch (error) {
    throw new TypeError('Error while parsing feature flags state');
  }
};

export const saveWhitelistedFlags = (
  flagsToPersist: string[],
  nextState: FeatureFlags,
  setter: StoredEntry<FeatureFlags>,
) => {
  const stateToSave = Object.fromEntries(
    Object.entries(nextState).filter(([key, _]) =>
      flagsToPersist.includes(key),
    ),
  );
  setter.set(JSON.stringify(stateToSave));
};

export const FLAGS_TO_PERSIST = [];
